import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [],
    entryComponents: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot()
    ],
    exports: [
        FormsModule,
        IonicModule
    ],
    providers: []
})
export class SharedModule {}
