import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AccountService } from './_services/accounts.service';
import { Account } from './_models/Account';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public appPages = [
    { title: 'Richieste', url: '/folder/Richieste', icon: 'mail' }
  ];
  firstName = '';
  email = '';
  subscription: Subscription;

  constructor(
    private authService: AccountService,
    private router: Router,
    private menu: MenuController) {}

  ngOnInit() {
    if (this.authService.currentUserValue != null) {
      this.firstName = this.authService.currentUserValue.firstName;
    }
    this.subscription = this.authService.currentUser.subscribe((_user: Account) => {
      if (_user != null) {
        this.firstName = _user.firstName;
        this.email = _user.email;
      } else {
        this.firstName = '';
        this.email = '';
      }
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  logout = () => {
    this.authService.logout();
    window.location.reload();
  };

  toggleMenu = () => this.menu.toggle(); //Add this method to your button click function
}
