import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../_models/Account';
import { environment } from '../../environments/environment';
import { CreateAccount } from '../_models/CreateAccount';
import { UpdateAccount } from '../_models/UpdateAccount';
import { ForgotPasswordRequest } from '../_models/ForgotPasswordRequest';
import { ResetPasswordRequest } from '../_models/ResetPasswordRequest';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountService {

    public currentUser: Observable<Account>;
    public tokenKey = '';           // Token for access Api
    private currentUserSubject: BehaviorSubject<Account>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Account>(JSON.parse(localStorage.getItem('ebitemp-currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
     }

    public get currentUserValue(): Account {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.localApiUrl}Account/authenticate`,
            { email, password })
            .pipe(map(user => {
                if (user && user.jwtToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('ebitemp-currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('ebitemp-currentUser');
        this.currentUserSubject.next(null);
    }

    forgotPassword(email: string) {
        return this.http.post<any>(`${environment.localApiUrl}Account/forgot-password`, { email })
            .toPromise();
    }

    resetPassword(token: string, password: string, confirmPassword: string){
        return this.http.post<any>(`${environment.localApiUrl}Account/reset-password`, {token, password, confirmPassword})
        .toPromise();
    }

    async getAccounts() {
        return this.http.get<Account[]>(`${environment.localApiUrl}Accounts`)
            .toPromise();
    }

    async getAccountById(id: string) {
        return this.http.get<Account>(`${environment.localApiUrl}Account/${id}`)
            .toPromise();
    }

    async createAccount(model: CreateAccount) {
        return this.http.post<Account>(`${environment.localApiUrl}Accounts`, model)
            .toPromise();
    }

    async updateAccount(id: string, model: UpdateAccount) {
        return this.http.put<Account>(`${environment.localApiUrl}Account/${id}`, model)
            .toPromise();
    }

    async deleteAccount(id: string) {
        return this.http.delete(`${environment.localApiUrl}Account/${id}`)
            .toPromise();
    }
}
